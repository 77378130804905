<template>
  <b-card
    class="card-custom gutter-b"
    no-body
  >
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <b-card-title>
        <h2 class="card-label">
          {{ trans('nav.aside.customers.all') }}
          <span class="d-block text-muted pt-2 font-size-sm">
            {{ trans('customers.index.desc') }}
          </span>
        </h2>
      </b-card-title>
      <!-- card-toolbar placeholder: add toolbar actions here :: <div class="card-toolbar">-->
    </div>
    <div class="card-body" style="min-height: 80px;">
      <b-table
        id="customers"
        primary-key="customerid"
        :fields="fields"
        :items="items"
        responsive="md"
      >
        <!--begin::name-->
        <template
          v-if="listProps.loading"
          v-slot:cell(name)
          class="customer-name"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(name)="data"
          class="customer-name"
        >
          {{ data.item.name }}
        </template>
        <!--end::name-->

        <!--begin::extId-->
        <template
          v-if="listProps.loading"
          v-slot:cell(extid)
          class="customer-extid"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(extid)="data"
          class="customer-extid"
        >
          {{ data.item.extid }}
        </template>
        <!--end::extId-->

        <!--begin::customer-id-->
        <template
          v-if="listProps.loading"
          v-slot:cell(id)
          class="customer-id"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(id)="data"
          class="customer-id"
        >
          {{ data.item.id }}
        </template>
        <!--end::customer-id-->

        <!--begin::createdOn-->
        <template
          v-if="listProps.loading"
          v-slot:cell(createdon)
          class="customer-createdon"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(createdon)="data"
          class="customer-extid"
        >
          {{ momentFullDate(data.item.createdon) }} @ {{ momentHoursMinutesSeconds(data.item.createdon) }}
        </template>
        <!--end::createdOn-->

        <!--begin::status-->
        <template
          v-if="listProps.loading"
          v-slot:cell(status)
          class="customer-status"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(status)="data"
        >
          <span
            class="label label-lg font-weight-bold label-inline"
            :class="`label-light-${ data.item.status ? 'success' : 'danger' } w-100`"
          >
            {{ data.item.status ? transChoice('global.active', 1) : transChoice('global.deleted', 1) | capitalize }}
          </span>
        </template>
        <!--end::status-->
      </b-table>

      <!--begin::more-btn-->
      <div class="d-flex justify-content-center align-items-center flex-wrap">
        <div class="d-flex align-items-center py-3">
          <div class="d-flex align-items-center">
            <Loader
              v-if="listProps.loading"
              :text="trans('customers.index.list.status.loading')"
              loader-class="relative"
              loader-style="margin-right: 80px;"
            ></Loader>
            <b-btn
              v-else-if="listProps.showMoreBtn"
              ref="users_showmorebtn"
              variant="primary"
              class="py-4 px-6"
              size="sm"
              @click="loadMore(listProps.lastVisibleDoc)"
            >
              {{ trans('customers.index.list.btn.loadMore') }}
            </b-btn>
          </div>
        </div>
      </div>
      <!--end::more-btn-->
    </div>
  </b-card>
</template>


<script>
  import * as objectPath from 'object-path'
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import useDateTime from '@src/use/datetime'

  import { capitalize } from '@src/core/helpers/textUtils'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { onBeforeMount, onMounted, ref, computed } from '@vue/composition-api'

  export default {
    name: 'CustomersIndex',
    components: { Loader: () => import('@src/components/content/Loader') },
    setup (props, { root, refs }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('nav.aside.customers.title'),
            route: 'users'
          },
          { title: trans('nav.aside.customers.all') }
        ])
      })

      const listProps = ref({
        limit: 10,
        loading: true,
        orderBy: 'name',
        sortOrder: 'asc',
        lastVisibleDoc: true,
        showMoreBtn: true
      })

      const listItems = ref(new Array(listProps.value.limit).fill({ })) // Create placeholders while loading

      const items = computed(() => listItems.value.map((user) => ({
        name: user.name,
        createdon: user.createdOn,
        extid: user.extId,
        id: user.id,
        status: user.deleted
      })))

      const fields = computed(() => [
        {
          key: 'name',
          label: capitalize(transChoice('global.name', 0)),
          sortable: true,
          tdClass: 'customer-name'
        },
        {
          key: 'extid',
          label: capitalize(trans('customers.index.list.fields.extid')),
          sortable: true,
          tdClass: 'customer-extid'
        },
        {
          key: 'id',
          label: capitalize(trans('customers.index.list.fields.id')),
          sortable: true,
          tdClass: 'customer-id'
        },
        {
          key: 'createdon',
          label: capitalize(trans('customers.index.list.fields.createdOn')),
          sortable: false,
          tdClass: 'customer-createdon',
          thClass: 'customer-createdon-head'
        },
        {
          key: 'status',
          label: capitalize(trans('customers.index.list.fields.status')),
          sortable: false,
          tdClass: 'customer-status',
          thClass: 'customer-status-head'
        }
      ])

      const loadMore = async (paginateDoc) => {
        const btn = refs.users_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getCustomers = fb.functions.httpsCallable('backendCustomersGetCustomers')

        return await getCustomers({
          data: {
            limit: listProps.value.limit,
            orderBy: listProps.value.orderBy,
            sort: listProps.value.sortOrder,
            paginateDoc
          }
        }).then((res) => {
          console.log('RESULT:', res)
          if (listProps.value.loading) listItems.value = []
          if (Object.keys(objectPath.get(res, 'data.result', {})).length < listProps.value.limit) listProps.value.showMoreBtn = false // eslint-disable-line max-len

          Object.keys(objectPath.get(res, 'data.result', {})).forEach((doc) => {
            listItems.value.push(res.data.result[doc])
          })

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          listProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      // Old "created" Vue trigger:
      loadMore(listProps.value.lastVisibleDoc).then(() => {
        listProps.value.loading = false
      })

      return {
        items,
        fields,
        listProps,
        trans,
        transChoice,
        momentFullDate,
        momentHoursMinutesSeconds,
        loadMore
      }
    }
  }
</script>

<style lang="scss">
  .list-item {
    overflow: hidden;

    &.loading {
      height: 1.8rem;
    }
  }
</style>
